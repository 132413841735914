import React from "react";
import { MDBView, MDBMask, MDBRow, MDBCol, MDBNavLink } from "mdbreact";

const Card = (props) => {

  const { item } = props

  return(
    <MDBCol xs="12" md="6" lg="6" className="p-1 m-0">
      <MDBNavLink to={"/albums/"+item.slug} style={{margin: 0, padding: 0}}>
        <MDBView zoom>
            <img src={item.image.thumb} className="img-fluid m-auto" alt={"Emmanuelle Margarita Photo "+item.name} />
            <MDBMask className="flex-center flex-column card-mask">
              <p className="h3-responsive text-center text-shadow">{item.name}</p>
            </MDBMask>
        </MDBView>
      </MDBNavLink>
    </MDBCol>
  );

}


const Galleries = (props) => {

    return(
      <MDBRow className="p-0 m-0 no-gutters justify-content-center">
        {props.records &&
          props.records.length > 0 &&
          props.records.map((item, key) => <Card key={key} item={item} />)
        }
      </MDBRow>
    );

}


export default Galleries;
