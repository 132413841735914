import React, { useEffect, useState} from "react";
import { MDBNavLink } from "mdbreact";
import Gallery from '../components/Gallery';
import { baseUrl } from '../constant.js';
import Socials from '../components/Socials';
import { Helmet } from 'react-helmet';

const Album = (props) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [record, setRecord] = useState(null);
  const slug = props.match.params.slug;

  const getRecord = () => {

    setLoading(true);

    fetch(baseUrl+"/api/galleries/"+slug)
      .then(res => res.json())
      .then(
        (result) => {
          setLoading(false);
          setRecord(result);
          setTimeout(() => { window.prerenderReady = true; }, 250);
        },
        (error) => {
          setLoading(false);
          setError(true);
          setRecord(null);
        }
      )
  }

  useEffect(() => {
    getRecord();
  }, []);

  const description = "Emmanuelle Margarita. Fashion, Celebrity, Portrait, Kids Photography";

  return(
    <div>

      {record &&
        <Helmet>
              <title>{record.name}</title>
              <meta name="description" content={description} />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={record.name}/>
              <meta property="og:description" content={description} />
              <meta property="og:image" content={record.images[0].thumb} />
              <meta property="og:image:height" content={record.images[0].preview.height} />
              <meta property="og:image:width" content={record.images[0].preview.width} />
        </Helmet>
      }


      <div className="back-to-home">
        <MDBNavLink to="/">{"<"}</MDBNavLink>
      </div>

      <header>
        {loading && <h1>Loading...</h1>}
        {record &&
          <div>
            <h1>{record.name}</h1>
            <h3 className="text-muted text-small">Emmanuelle Margarita</h3>
            <p className="lead">{record.description}</p>
            <Socials />

          </div>
        }
      </header>

      {record && <Gallery record={record} />}
      {error && <p>Oups something went wrong!</p>}
    </div>
  );

}


export default Album;
